import "./Pages.scss";
import Breadcromb from "./Breadcromb";

function About() {
  return (
    <>
      <div className="about-content">
        <Breadcromb subheadingname="Get to know me" />

        <div className="about-heading">About Me</div>

        <div className="bottom-border"></div>
  <div className="about-image">
     <div className="overlay-aboutimage"> </div></div>

        <div className="about-text">
          <h2>Who am i?</h2>

          <div className="content-title">
            <h6>
              I'm Amit Dhanger, a visual UX/UI Designer,Javscript and React
              Developer.
            </h6>
          </div>

          <p>
            I am a front-end developer.I can provide clean code and pixel
            perfect design. I also make the website more & more interactive with
            the web animation. I am a driven and motivated individual, and I am
            always looking for new opportunities to challenge myself and make a
            positive impact. Please feel free to view my portfoli o and connect
            with me
          </p>

          <table className="info-data">
            <tr>
              <th>Name</th>
              <td>Amit Dhanger</td>
            </tr>

            <tr>
              <th>Education</th>
              <td>
                <a href="https://www.sundayschool.co.in/">Sunday School</a>
              </td>
            </tr>

            <tr>
              <th>Email</th>
              <td>amitdeveloper160@gmail.com</td>
            </tr>

            <tr>
              <th>From</th>
              <td>Gurugram</td>
            </tr>
          </table>

          <button>Download CV</button>
        </div>
      </div>
    </>
  );
}

export default About;
