import Header from './Components/Header';
import Footer from './Components/Footer';
import Home from './Pages/Home';
import About from './Pages/About';
import Portfolio from './Pages/Portfolio';
import Skill from './Pages/Skills';
import Contact from './Pages/Contact';

import './App.scss';
import {Routes,Route} from 'react-router-dom';
import 'font-awesome/css/font-awesome.min.css';


function App() {
  return (
    <div className="App">
      <Header/>
      <Routes>
        <Route path="" element={<Home/>}/>
        <Route path="/about" element={<About/>}/>
        <Route path="/portfolio" element={<Portfolio/>}/>
        <Route path="/skill" element={<Skill/>}/>
        <Route path="/contact" element={<Contact/>}/>

      </Routes>
      <Footer/>
    
    </div>
  );
}

export default App;
