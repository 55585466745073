import "./Pages.scss";
import {NavLink} from 'react-router-dom'

function Home() {
  return (
    <>
      <div className="home-content">
        <div className="personalintro">
          <div className="colorlayer">
            <div className="personalintrotext">
              <div className="my-name">Amit Dhanger</div>

              <div className="profession">
                <div className="wrapper">
                  <div className="static-text">I am</div>

                  <ul className="dynamic-text">
                    <li>
                      <span>Frontend Developer</span>
                    </li>
                    <li>
                      <span>Javascript Developer</span>
                    </li>
                    <li>
                      <span>React Developer</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="resume-button">
              <button>Download CV</button>
            </div>
          </div>
        </div>

        <div className="why-me">
          <div className="reason-skill">
            <h1>
              Why Hire Me For Your
              <br /> Next<span> Project?</span>
            </h1>

            <p>
              I'm specialist in UI/UX Designe. My passion is designing & solving
              problems through user experience and research.
            </p>

            <button className="homebtn"><NavLink to="/contact">Hire Me</NavLink></button>
          </div>

          <div className="reason-skillshow">
            <div className="skill-showboxcolumn1">
              <div className="rsbox1">
                <div className="skill-icon">
                  <i className="fa fa-diamond"></i>
                </div>
                <h2>Visual Design</h2>
                <p>Create user interface with unique & modern ideas</p>
              </div>

              <div className="rsbox2">
                <div className="skill-icon">
                  <i className="fa fa-graduation-cap"></i>
                </div>
                <h2>Ux Research</h2>
                <p>Create Digital user products with updated ideas</p>
              </div>
            </div>

            <div className="skill-showboxcolumn2">
              <div className="rsbox1">
                <div className="skill-icon">
                  <i class="fa fa-heartbeat"></i>
                </div>
                <h2> Design Prototype</h2>
                <p>Create advance design prototype with figma apps</p>
              </div>
            </div>
          </div>
        </div>

   
        
      </div>
    </>
  );
}

export default Home;
