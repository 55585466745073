import './Components.scss';



function Footer() {
  return (
    <>
      <div className="footer">

      <div className="developer">
        Made in
          <a href="https://react.dev/">
             React.js
          </a>
            By Amit Dhanger 2023
        </div>

      </div>


  
    </>
  )
}

export default Footer