import "./Pages.scss";
import portfolio1 from "../Assests/Images/portfolio1.jpg";
import portfolio2 from "../Assests/Images/portfolio2.jpg";
import portfolio3 from "../Assests/Images/portfolio3.jpg";
import portfolio4 from "../Assests/Images/portfolio4.jpg";
import portfolio5 from "../Assests/Images/portfolio5.jpg";
import portfolio6 from "../Assests/Images/portfolio6.jpg";
import Breadcromb from "./Breadcromb";

function Portfolio() {
  return (
    <>
      <div className="portfolio-content">
        <Breadcromb subheadingname="Showcasing some of my best work" />

        <div className="portfolio-heading">Portfolio</div>

        <div className="bottom-border"></div>

        <div className="portfolio-images">
          <div className="portfolio-image">
          <a href="https://github.com/Amitdhanger/javascript-slick-carousel">  <img src={portfolio1} alt="Javascript Slick Carousel" /></a>
       <a href="https://github.com/Amitdhanger/javascript-slick-carousel"> <h2>Javascript Slick Carousel</h2></a>  
          </div>

          <div className="portfolio-image">
           <a href="https://github.com/Amitdhanger/digitalclock"> <img src={portfolio2} alt="Digital Clock" /></a>
           <a href="https://github.com/Amitdhanger/digitalclock"><h2>Digital Clock</h2></a> 
          </div>

          <div className="portfolio-image">
          <a href="https://github.com/Amitdhanger/bootstrap-work"> <img src={portfolio3} alt="Bootstrap Work" /></a>
           <a href="https://github.com/Amitdhanger/bootstrap-work"><h2>Bootstrap Work</h2></a> 
          </div>

          <div className="portfolio-image">
           <a href="https://github.com/Amitdhanger/javascript-character-count"> <img src={portfolio4} alt=" Javascript Character Countdown" /></a>
           <a href="https://github.com/Amitdhanger/javascript-character-count"><h2> Javascript Character Countdown</h2></a> 
          </div>

          <div className="portfolio-image">
          <a href="https://github.com/Amitdhanger/javascript-countdown-timer">  <img src={portfolio5} alt="Javascript Countdown Timer" /></a>
          <a href="https://github.com/Amitdhanger/javascript-countdown-timer"> <h2>Javascript Countdown Timer</h2></a> 
          </div>

          <div className="portfolio-image">
           <a href="https://github.com/Amitdhanger/popupandformjavascript"> <img src={portfolio6} alt="Javascript Modal" /></a>
          <a href="https://github.com/Amitdhanger/popupandformjavascript"><h2>Javascript Modal</h2></a>  
          </div>
        </div>
      </div>
    </>
  );
}

export default Portfolio;
