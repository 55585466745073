import Breadcromb from './Breadcromb';
import './Pages.scss';
function Skill(){
    return(
        <>
        <div className="skill-content">
            <Breadcromb subheadingname="Skills I Know"/>
        <h1 className="title-text">My Skills</h1>
        <div className='bottom-border'></div>
        <div className="skill-show">
        <div className="skill-details">
                  

                  <div className="skill-box">
                    <span className="skill-name">HTML</span>
                    <div className="skill-bar">
                        <span className="skill-per html">
                            <span className="tooltip">95%</span>
                        </span>
                    </div>
                  </div>

                  <div className="skill-box">
                    <span className="skill-name">CSS</span>
                    <div className="skill-bar">
                        <span className="skill-per css">
                            <span className="tooltip">80%</span>
                        </span>
                    </div>
                  </div>

                  <div className="skill-box">
                    <span className="skill-name">BOOTSTRAP</span>
                    <div className="skill-bar">
                        <span className="skill-per bootstrap">
                            <span className="tooltip">85%</span>
                        </span>
                    </div>
                  </div>

                  <div className="skill-box">
                    <span className="skill-name">FIGMA</span>
                    <div className="skill-bar">
                        <span className="skill-per figma">
                            <span className="tooltip">65%</span>
                        </span>
                    </div>
                  </div>

                  <div className="skill-box">
                    <span className="skill-name">JAVASCRIPT</span>
                    <div className="skill-bar">
                        <span className="skill-per javascript">
                            <span className="tooltip">70%</span>
                        </span>
                    </div>
                  </div>

                  
                  <div className="skill-box">
                    <span className="skill-name">JQUERY</span>
                    <div className="skill-bar">
                        <span className="skill-per jquery">
                            <span className="tooltip">65%</span>
                        </span>
                    </div>
                  </div>

                  <div className="skill-box">
                    <span className="skill-name">REACT</span>
                    <div className="skill-bar">
                        <span className="skill-per react">
                            <span className="tooltip">50%</span>
                        </span>
                    </div>
                  </div>

       </div>

       </div>

        </div>
        </>
    )
}

export default Skill