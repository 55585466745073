import './Components.scss';
import { NavLink } from 'react-router-dom';
import logo from '../Assests/icon/logo.png';

function Header(){
    return(
        <>
    <div className="nav-bar">

<div className="my-logo">
  
<NavLink to="/"> <img src={logo} alt="logo"/></NavLink>
</div>

<div className="nav-link">

<NavLink to="/">Home</NavLink>
<NavLink to="/about">About</NavLink>
<NavLink to="/portfolio">Portfolio</NavLink>
<NavLink to="/skill">Skills</NavLink>
<NavLink to="/contact">Contact</NavLink>

</div>



</div>

</>
    )
}

export default Header