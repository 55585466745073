import Breadcromb from "./Breadcromb";

function Contact() {
  return (
    <>
      <div className="contact-content">
        <Breadcromb subheadingname="Feel free to contact me anytimes" />

        <div className="contact-heading">Contact Me</div>

        <div className="bottom-border"></div>

        <div className="contact-info">
          <div className="box">
            <div className="icon">
              {" "}
              <i className="fa fa-map-marker"></i>
            </div>

            <div className="text">
              <h3>Address</h3>
              <p>Sector-13,Gurugram , Haryana</p>
            </div>
          </div>

          <div className="box">
            <div className="icon">
              {" "}
              <i className="fa fa-phone"></i>
            </div>

            <div className="text">
              <h3>Phone</h3>
              <p>9306220796</p>
            </div>
          </div>

          <div className="box">
            <div className="icon">
              {" "}
              <i className="fa fa-envelope"></i>
            </div>

            <div className="text">
              <h3>Email</h3>
              <p>amitdeveloper160@gmail.com</p>
            </div>
          </div>
        </div>
      

      <div className="map">
        <iframe
          title="map"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14028.750517798835!2d77.0423906!3d28.473895549999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d19b3ba1ba4fd%3A0x53aa965c2f48ea47!2sSector%2013%2C%20Gurugram%2C%20Haryana%20122022!5e0!3m2!1sen!2sin!4v1685273704686!5m2!1sen!2sin"
          width="900"
          height="300"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          alt="map"
        ></iframe>
      </div>
      </div>
    </>
  );
}

export default Contact;
